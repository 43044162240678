@font-face {
  font-family: 'Barlow';
  src: url('../../../fonts/Barlow-Bold.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Bold.woff') format('woff'),
  url('../../../fonts/Barlow-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../../fonts/Barlow-Medium.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Medium.woff') format('woff'),
  url('../../../fonts/Barlow-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../../fonts/Barlow-Regular.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Regular.woff') format('woff'),
  url('../../../fonts/Barlow-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('../../../fonts/Barlow-Light.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Light.woff') format('woff'),
  url('../../../fonts/Barlow-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Source Sans 3';
  src: url('../../../fonts/SourceSans3-Light.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Light.woff') format('woff'),
  url('../../../fonts/Barlow-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src: url('../../../fonts/SourceSans3-Regular.eot') format('embedded-opentype');
  src: url('../../../fonts/Barlow-Regular.woff') format('woff'),
  url('../../../fonts/Barlow-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

h1, h2, h3, h4, h5 {
  font-family: 'Barlow', sans-serif !important;
}

p, span, a, li {
  font-family: 'Source Sans 3', sans-serif !important;
}

:root {
  --h1: 55px;
  --h2: 35px;
  --h3: 30px;
  --h4: 25px;
  --h5: 20px;
  --body: 16px;
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

h5 {
  font-size: var(--h5);
}

p, span, a, li {
  font-size: var(--body);
}

