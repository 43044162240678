.sidebar {
  width: 33%;
  min-width: 500px;
  top: 0;
  height: 100vh;
  background-color: white;
  position: fixed;
  right: -100%;
  transition: 0.25s;
  transition-timing-function: ease-in-out;
  &.active {
    right: 0;
  }
  .SidebarInner {
    padding: 25px;
    display: flex;
    flex-direction: column;
    .SidebarHeader {
      display: flex;
      justify-content: left;
      color: var(--grey);
      a {
        font-size: 20px !important;
      }
      .SidebarHeader__Close {
        &:hover {
          cursor: pointer;
          .SidebarHeader__Icon {
            transform: scale(1.15);
          }
        }
        .SidebarHeader__Icon {
          margin-right: 15px;
          color: var(--primary);
          transition: 0.25s;
        }
      }

    }
    .SidebarContent {
      margin-top: 50px;
      .SidebarContentHeader {

      }
      .SidebarContentBody {
        text-align: left;
        color: var(--dark);
        .accordion {
          margin-top: 25px;
          .MuiPaper-elevation {
            box-shadow: none;
            background-color: var(--highlight);
          }
          .AccordionHeaderTitle {
            font-weight: 700;
            font-family: 'Barlow', sans-serif !important;
            margin-top: 0;
            margin-bottom: 0;
            color: var(--grey);
          }
        }
      }
    }
  }
}

.devider {
  width: 50px;
  height: 10px;
  border-radius: 15px;
  background-color: var(--primary);
}

.readmore {
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 15px;
}

.SceneIcon {
  width: 85px;
  height: 85px;
  display: flex;
  background-color: var(--lightblue);
  border-radius: 100%;
  img {
    width: 100%;
    height: auto;
    padding: 15px;
  }
}
