.MapMarker {
  position: absolute;
  cursor: pointer;
  height: 60px;
  display: flex;
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .MapMarkerIcon {
    width: 35px;
    height: 35px;
    background-color: var(--primary);
    border: solid 2px white;
    border-radius: 100%;
    transition: 0.25s;
    animation-name: animationIcon;
    animation-duration: 0.75s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  .MapMarkerDescription {
    background-color: var(--primary);
    border-radius: 10px;
    padding: 0;
    margin-left: 15px;
    opacity: 0;
    transform: translateX(-15px);
    transition: 0.25s;
    width: 0;
    overflow: hidden;
    p {
      font-weight: 600;
      font-size: var(--h5);
      color: white;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &:hover {
    cursor: auto;
    .MapMarkerIcon {
      animation-play-state: paused;
      opacity: 0.5;
    }
    .MapMarkerDescription {
      cursor: pointer;
      transform: translateX(0);
      opacity: 1;
      width: auto;
      overflow: visible;
      padding: 10px 15px;
      z-index: 1;
      box-shadow: 0px 0px 12px #ffffff75;
    }
  }

}

@keyframes animationIcon {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}