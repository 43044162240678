.MapWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: linear-gradient(45deg, var(--lightblue), white);
  .map-inner {
    margin-top: -225px;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: .25s;
    width: 100%;
    max-width: 1700px;

    &.locked {
      pointer-events: none;
      transform: scale(1.1) translateX(-150px) translateY(0px) !important;
    }
    &.zoomed:hover {
      cursor: grab;
    }
    .MapImage {
      width: 70%;
      height: auto;
      pointer-events: none;
    }
  }
}

.zoom-buttons {
  position: absolute;
  left: 25px;
  bottom: 25px;
  z-index: 9;
  &.hidden {
    display: none;
  }
  button {
    -webkit-appearance: none;
    border: solid 2px var(--primary);
    background-color: var(--lightblue);
    color: var(--primary);
    font-size: 20px;
    margin-right: 5px;
    width: 65px;
    height: 65px;
    cursor: pointer;
    transition: 0.25s;
    opacity: 0.75;
    border-radius: 50px;
    &:hover {
      opacity: 1;
      color: var(--highlight);
      background-color: var(--primary);
    }
    &.reset {
      width: 100px;
    }
  }
}

@media(max-width: 1200px) {
  .MapWrapper .map-inner {
    margin-top: -125px;
  }
  .MapWrapper .map-inner .MapImage {
    width: 75%;
  }
}

@media(max-width: 768px) {
  .MapWrapper .map-inner {
    margin-top: -50px;
  }
  .MapMarker .MapMarkerIcon {
    width: 25px !important;
    height: 25px !important;
  }
  .MapMarker {
    .MapMarkerDescription {
      padding: 8px 10px !important;
      p {
        font-size: 18px !important;
      }
    }
    &:hover {
      .MapMarkerDescription {
        transform: translateX(-10px) !important;
      }
    }
  }
}

@media(max-width: 576px) {
  .MapWrapper .map-inner {
    margin-top: -50px;
  }
  .MapMarker .MapMarkerIcon {
    width: 15px !important;
    height: 15px !important;
  }
  .MapMarker {
    height: 29px !important;
    transform: translate(-20px, -0px);
    &.Scene_10 {
      left: 75% !important;
    }
    &.Scene_5 {
      left: 83% !important;
      &:hover {
        .MapMarkerDescription {
          transform: translateX(-190px)!important;
        }
      }
    }
    &.Scene_8 {
      left: 55% !important;
    }
    &.Scene_11 {
      left: 63% !important;
      &:hover {
        .MapMarkerDescription {
          transform: translateX(-180px)!important;
        }
      }
    }
    .MapMarkerDescription {
      pointer-events: none;
      padding: 5px 10px !important;
      z-index: 9999;
      position: relative;
      p {
        font-size: 16px !important;
      }
    }
    &:hover {
      z-index: 99;
      .MapMarkerDescription {
        pointer-events: auto;
        transform: translateX(-10px) !important;
      }
    }
  }
  .MapWrapper .map-inner .MapImage {
    width: 110%;
    margin-left: -20px;
  }
}