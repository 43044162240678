.nav-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
}

.nav-wrapper {
  width: calc(100% - 100px);
  margin: auto;
}

.home {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  img {
    width: 100%;
    height: auto;
    transition: 0.25s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

